import { Button, Col, Input, Row, Space, Table, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { FC } from "react";
import "./App.css";

const { Title, Text } = Typography;

type Numberpair = {
  red: string;
  blue: string;
};

const App: FC = () => {
  const [inputRedNumbers, setInputRedNumbers] = React.useState("");
  const [inputBlueNumbers, setInputBlueNumbers] = React.useState("");

  const [inputList, setInputList] = React.useState<Numberpair[]>([]);

  const [inputUrl, setInputUrl] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [selectingRedNumbers, setSelectingRedNumbers] = React.useState("");
  const [selectingBlueNumbers, setSelectingBlueNumbers] = React.useState("");

  const [selectedNumbersList, setSelectedNumbersList] = React.useState<
    Numberpair[]
  >([]);

  const allRedNumbers: number[] = [];
  inputList.forEach((input) =>
    input.red
      .split(" ")
      .map((n) => parseInt(n))
      .filter((n) => !Number.isNaN(n))
      .forEach((n) => allRedNumbers.push(n))
  );
  const redNumberCountMap = new Map<number, number>();
  allRedNumbers.forEach((n) => {
    if (redNumberCountMap.has(n)) {
      redNumberCountMap.set(n, redNumberCountMap.get(n)! + 1);
    } else {
      redNumberCountMap.set(n, 1);
    }
  });

  const allBlueNumbers: number[] = [];
  inputList.forEach((input) =>
    input.blue
      .split(" ")
      .map((n) => parseInt(n))
      .filter((n) => !Number.isNaN(n))
      .forEach((n) => allBlueNumbers.push(n))
  );
  const blueNumberCountMap = new Map<number, number>();
  allBlueNumbers.forEach((n) => {
    if (blueNumberCountMap.has(n)) {
      blueNumberCountMap.set(n, blueNumberCountMap.get(n)! + 1);
    } else {
      blueNumberCountMap.set(n, 1);
    }
  });

  return (
    <Row>
      <Col span={1}></Col>
      <Col span={8}>
        <Space direction="vertical">
          <Title level={1}>参考号码</Title>
          <Space>
            <Space direction="vertical">
              <TextArea
                autoSize={true}
                placeholder="红球空格分割"
                value={inputRedNumbers}
                onChange={(e) => setInputRedNumbers(e.target.value)}
              ></TextArea>
              <TextArea
                autoSize={true}
                placeholder="篮球空格分割"
                value={inputBlueNumbers}
                onChange={(e) => setInputBlueNumbers(e.target.value)}
              ></TextArea>
            </Space>
            <Button
              onClick={() => {
                if (
                  inputRedNumbers.length === 0 ||
                  inputBlueNumbers.length === 0
                ) {
                  return;
                }
                setInputList(
                  inputList.concat({
                    red: inputRedNumbers,
                    blue: inputBlueNumbers,
                  })
                );
                setInputRedNumbers("");
                setInputBlueNumbers("");
              }}
            >
              增加
            </Button>
          </Space>
          <Space>
            <TextArea
              autoSize
              placeholder="图片链接"
              value={inputUrl}
              onChange={(e) => setInputUrl(e.target.value)}
            ></TextArea>
            <Button
              loading={isLoading}
              onClick={() => {
                setIsLoading(true);
                fetch(
                  new Request(
                    "/rest/2.0/ocr/v1/accurate?access_token=24.e63aaec37dcde12dac955ee188edc04e.2592000.1642652224.282335-25385545",
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                      body: `url=${inputUrl}&language_type=CHN_ENG&detect_direction=false¶graph=false&probability=false`,
                    }
                  )
                )
                  .then((response) => response.json())
                  .then((json) => {
                    setIsLoading(false);
                    if (json.error_code) {
                      return;
                    }
                    let foreNumbers: string[] = [];
                    let foreAnchorResults: any[] = [];
                    let backNumbers: string[] = [];
                    for (const wordResult of json.words_result) {
                      if (
                        wordResult.words.includes("前区") ||
                        wordResult.words.includes("红复")
                      ) {
                        foreAnchorResults.push(wordResult);
                        foreNumbers.push(wordResult.words);
                      } else if (
                        wordResult.words.includes("后区") ||
                        wordResult.words.includes("蓝单")
                      ) {
                        backNumbers.push(wordResult.words);
                      } else {
                        foreAnchorResults.forEach((anchorResult, index) => {
                          if (
                            wordResult.location.top -
                              anchorResult.location.top >=
                              0 &&
                            wordResult.location.top -
                              anchorResult.location.top <=
                              40 &&
                            wordResult.location.left -
                              anchorResult.location.left >=
                              0 &&
                            wordResult.location.left -
                              anchorResult.location.left <=
                              200
                          ) {
                            foreNumbers[index] += wordResult.words;
                          }
                        });
                      }
                    }

                    const resultFore = foreNumbers
                      .map((number) => number.match(/\d+/g)?.at(0))
                      .map((number) => number?.match(/.{1,2}/g)?.join(" "));

                    const resultBack = backNumbers
                      .map((number) => number.match(/\d+/g)?.at(0))
                      .map((number) => number?.match(/.{1,2}/g)?.join(" "));

                    let pairs: Numberpair[] = [];
                    for (let index = 0; index < resultFore.length; index++) {
                      const fore = resultFore[index];
                      const back = resultBack[index];

                      if (fore !== undefined && back !== undefined) {
                        pairs.push({
                          red: fore!,
                          blue: back!,
                        });
                      }
                    }

                    setInputUrl("");
                    setInputList(inputList.concat(pairs));
                  })
                  .catch((_) => setIsLoading(false));
              }}
            >
              图片识别
            </Button>
          </Space>
          {inputList.map((input) => {
            const redNumbers = input.red
              .split(" ")
              .map((n) => parseInt(n))
              .filter((n) => !Number.isNaN(n));
            const selectingRedNums = selectingRedNumbers
              .split(" ")
              .map((n) => parseInt(n))
              .filter((n) => !Number.isNaN(n));
            const redTexts = redNumbers.map((n) =>
              selectingRedNums.includes(n) ? (
                <Text key={n.toString()} type="danger" strong>
                  {n}
                </Text>
              ) : (
                <Text key={n.toString()}>{n}</Text>
              )
            );

            const blueNumbers = input.blue
              .split(" ")
              .map((n) => parseInt(n))
              .filter((n) => !Number.isNaN(n));
            const selectingBlueNums = selectingBlueNumbers
              .split(" ")
              .map((n) => parseInt(n))
              .filter((n) => !Number.isNaN(n));
            const blueTexts = blueNumbers.map((n) =>
              selectingBlueNums.includes(n) ? (
                <Text key={n.toString()} type="danger" strong>
                  {n}
                </Text>
              ) : (
                <Text key={n.toString()}>{n}</Text>
              )
            );

            return (
              <Space
                key={input.red}
                wrap={true}
                onDoubleClick={() =>
                  setInputList(inputList.filter((n) => n !== input))
                }
              >
                {redTexts}|{blueTexts}
              </Space>
            );
          })}
        </Space>
      </Col>
      <Col span={8}>
        <Space direction="vertical">
          <Title>统计</Title>
          <Space align="start">
            <Table
              columns={[
                {
                  title: "红球",
                  dataIndex: "number",
                  key: "number",
                },
                {
                  title: "次数",
                  dataIndex: "count",
                  key: "count",
                },
              ]}
              dataSource={Array.from(redNumberCountMap.keys())
                .sort(
                  (a, b) =>
                    redNumberCountMap.get(b)! - redNumberCountMap.get(a)!
                )
                .map((n) => ({
                  key: n,
                  count: redNumberCountMap.get(n),
                  number: n,
                }))}
            ></Table>
            <Table
              columns={[
                {
                  title: "蓝球",
                  dataIndex: "number",
                  key: "number",
                },
                {
                  title: "次数",
                  dataIndex: "count",
                  key: "count",
                },
              ]}
              dataSource={Array.from(blueNumberCountMap.keys())
                .sort(
                  (a, b) =>
                    blueNumberCountMap.get(b)! - blueNumberCountMap.get(a)!
                )
                .map((n) => ({
                  key: n,
                  count: blueNumberCountMap.get(n),
                  number: n,
                }))}
            ></Table>
          </Space>
        </Space>
      </Col>
      <Col span={6}>
        <Space direction="vertical">
          <Title>自选号码</Title>
          <Space>
            <Space direction="vertical">
              <TextArea
                autoSize={true}
                placeholder="红球空格分割"
                value={selectingRedNumbers}
                onChange={(e) => setSelectingRedNumbers(e.target.value)}
              ></TextArea>
              <TextArea
                autoSize={true}
                placeholder="篮球空格分割"
                value={selectingBlueNumbers}
                onChange={(e) => setSelectingBlueNumbers(e.target.value)}
              ></TextArea>
            </Space>
            <Button
              onClick={() => {
                if (
                  selectingRedNumbers.length === 0 ||
                  selectingBlueNumbers.length === 0
                ) {
                  return;
                }
                setSelectedNumbersList(
                  selectedNumbersList.concat({
                    red: selectingRedNumbers,
                    blue: selectingBlueNumbers,
                  })
                );
                setSelectingRedNumbers("");
                setSelectingBlueNumbers("");
              }}
            >
              加入备选
            </Button>
          </Space>
          {selectedNumbersList.map((number) => (
            <Space
              onDoubleClick={() =>
                setSelectedNumbersList(
                  selectedNumbersList.filter((n) => n !== number)
                )
              }
            >
              <Text key={number.red}>
                {number.red} | {number.blue}
              </Text>
            </Space>
          ))}
        </Space>
      </Col>
      <Col span={1}></Col>
    </Row>
  );
};

export default App;
